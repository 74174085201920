import {
  MemberRoleEdit_default
} from "./chunk-3TQ77Y6J.js";
import {
  MemberRoleViewModelContextProvider
} from "./chunk-N5PAYPRJ.js";

// src/pages/MemberRole/edit.tsx
import React, { Component } from "react";
import { observer } from "mobx-react";
var EditMemberRoleProvider = observer(
  class EditMemberRoleProvider2 extends Component {
    render() {
      return /* @__PURE__ */ React.createElement(MemberRoleViewModelContextProvider, null, /* @__PURE__ */ React.createElement(MemberRoleEdit_default, null));
    }
  }
);

export {
  EditMemberRoleProvider
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
