import {
  RoleInformation_default
} from "./chunk-BDBC6HUL.js";
import {
  withRoleViewModel
} from "./chunk-6TGZT635.js";
import {
  EditHeader
} from "./chunk-ZVOJGXKE.js";
import {
  A as A2
} from "./chunk-Z3CB7RJ7.js";
import {
  A
} from "./chunk-TSTKR7BL.js";
import {
  Spinner
} from "./chunk-POLRV4GK.js";
import {
  A as A3
} from "./chunk-NNGRNXS6.js";
import {
  PAGE_STATUS
} from "./chunk-OSVSI4BW.js";
import {
  __publicField
} from "./chunk-JCO6WRHX.js";

// src/pages/Roles/RoleEdit/index.tsx
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { ORGANISATION_ROLE_FIELD } from "aesirx-lib";
import SimpleReactValidator from "simple-react-validator";
import _ from "lodash";
var EditRole = observer(
  class EditRole2 extends Component {
    constructor(props) {
      super(props);
      __publicField(this, "roleDetailViewModel", null);
      __publicField(this, "validator");
      __publicField(this, "formPropsData", { [ORGANISATION_ROLE_FIELD.CUSTOM_FIELDS]: {} });
      __publicField(this, "isEdit", false);
      __publicField(this, "debouncedChangeHandler", _.debounce((value) => {
        this.roleDetailViewModel.handleAliasChange(value);
      }, 300));
      this.state = {};
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.roleDetailViewModel = props.model?.roleDetailViewModel ? props.model?.roleDetailViewModel : null;
      this.roleDetailViewModel.setForm(this);
      this.isEdit = props.match.params?.id ? true : false;
    }
    async componentDidMount() {
      const { match } = this.props;
      if (this.isEdit) {
        this.formPropsData[ORGANISATION_ROLE_FIELD.ID] = match.params?.id;
        await this.roleDetailViewModel.initializeData();
      }
    }
    handleValidateForm() {
      if (this.validator.fields["Role Name"] === true) {
        this.setState((prevState) => {
          return {
            ...prevState,
            requiredField: Math.random()
          };
        });
      }
      this.validator.showMessages();
    }
    render() {
      const { t, history } = this.props;
      console.log("rerender Role");
      return /* @__PURE__ */ React.createElement("div", { className: "py-4 px-3 h-100 d-flex flex-column" }, this.roleDetailViewModel.formStatus === PAGE_STATUS.LOADING && /* @__PURE__ */ React.createElement(Spinner, { className: "spinner-overlay" }), /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center justify-content-between mb-24 flex-wrap" }, /* @__PURE__ */ React.createElement(
        EditHeader,
        {
          props: this.props,
          title: t("txt_role"),
          isEdit: this.isEdit,
          redirectUrl: "/roles"
        }
      ), /* @__PURE__ */ React.createElement("div", { className: "position-relative" }, /* @__PURE__ */ React.createElement(
        A3,
        {
          buttons: [
            {
              title: t("txt_cancel"),
              handle: async () => {
                history.push(`/roles`);
              },
              icon: "/assets/images/cancel.svg"
            },
            {
              title: t("txt_save_close"),
              handle: async () => {
                if (this.validator.allValid()) {
                  const result = this.isEdit ? await this.roleDetailViewModel.update() : await this.roleDetailViewModel.create();
                  if (!result?.error) {
                    history.push(`/roles`);
                  }
                } else {
                  this.handleValidateForm();
                }
              }
            },
            {
              title: t("txt_save"),
              validator: this.validator,
              handle: async () => {
                if (this.validator.allValid()) {
                  if (this.isEdit) {
                    await this.roleDetailViewModel.update();
                    await this.roleDetailViewModel.initializeData();
                    this.forceUpdate();
                  } else {
                    const result = await this.roleDetailViewModel.create();
                    if (!result?.error) {
                      history.push(`/roles/edit/${result?.response}`);
                    }
                  }
                } else {
                  this.handleValidateForm();
                }
              },
              icon: "/assets/images/save.svg",
              variant: "success"
            }
          ]
        }
      ))), /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(Row, { className: "gx-24 mb-24" }, /* @__PURE__ */ React.createElement(Col, { lg: 9 }, /* @__PURE__ */ React.createElement(Form.Group, { className: `mb-24` }, /* @__PURE__ */ React.createElement(
        A,
        {
          field: {
            getValueSelected: this.roleDetailViewModel.roleDetailViewModel.formPropsData[ORGANISATION_ROLE_FIELD.ROLE_NAME],
            classNameInput: "py-10 fs-4",
            placeholder: t("txt_add_role_name"),
            handleChange: (event) => {
              this.roleDetailViewModel.handleFormPropsData(
                ORGANISATION_ROLE_FIELD.ROLE_NAME,
                event.target.value
              );
            },
            required: true,
            validation: "required",
            blurred: () => {
              this.validator.showMessageFor("Role Name");
            }
          }
        }
      ), this.validator.message(
        "Role Name",
        this.roleDetailViewModel.roleDetailViewModel.formPropsData[ORGANISATION_ROLE_FIELD.ROLE_NAME],
        "required",
        {
          className: "text-danger mt-8px"
        }
      )), /* @__PURE__ */ React.createElement(
        RoleInformation_default,
        {
          validator: this.validator,
          messagesShown: this.validator.messagesShown,
          isEdit: this.isEdit,
          formPropsData: this.roleDetailViewModel.roleDetailViewModel.formPropsData,
          ...this.props
        }
      )), /* @__PURE__ */ React.createElement(Col, { lg: 3 }, /* @__PURE__ */ React.createElement(
        A2,
        {
          detailViewModal: this.roleDetailViewModel,
          formPropsData: this.roleDetailViewModel.roleDetailViewModel.formPropsData,
          isEdit: this.isEdit,
          isFeatured: false,
          isPublishedSimple: true
        }
      )))));
    }
  }
);
var RoleEdit_default = withTranslation()(withRouter(withRoleViewModel(EditRole)));

export {
  RoleEdit_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
