import {
  ListRole_default
} from "./chunk-O5G4WOTG.js";
import {
  RoleViewModelContextProvider
} from "./chunk-6TGZT635.js";

// src/pages/Roles/index.tsx
import React from "react";
import { observer } from "mobx-react";
var RolesPage = observer(
  class RolesPage2 extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return /* @__PURE__ */ React.createElement("div", { className: "px-3 py-4" }, /* @__PURE__ */ React.createElement(RoleViewModelContextProvider, null, /* @__PURE__ */ React.createElement(ListRole_default, null)));
    }
  }
);

export {
  RolesPage
};
