import {
  Route_default
} from "./chunk-OZKCIIKP.js";
import {
  SubTypeItemModel,
  SubTypeModel
} from "./chunk-VVAASJP7.js";

// src/Pim/PimSubType/index.ts
import axios from "axios";
var SubTypeApiService = class {
  constructor() {
    this.route = null;
    this.create = async (data) => {
      try {
        const result = await this.route.create(data);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.update = async (data) => {
      try {
        const result = await this.route.update(data);
        if (result) {
          return result;
        }
        return { message: "Something have problem" };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.delete = async (data) => {
      try {
        const response = await this.route.delete(data);
        if (response) {
          return response;
        } else {
          return false;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getList = async (filter) => {
      try {
        const data = await this.route.getList(filter);
        const roleItems = new SubTypeModel(data);
        return {
          listItems: roleItems.getItems(),
          pagination: roleItems.getPagination()
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.getDetail = async (id = 0) => {
      try {
        const data = await this.route.getDetail(id);
        let results = null;
        if (data) {
          results = new SubTypeItemModel(data);
        }
        if (results) {
          results = results.toJSON();
        }
        return results;
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.route = new Route_default();
  }
};

export {
  SubTypeApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
