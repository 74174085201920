import {
  __publicField
} from "./chunk-XEO3YXBM.js";

// src/store/DamStore/DamStore.js
import { AesirxDamApiService, DAM_COLLECTION_FIELD_KEY } from "aesirx-lib";
import { runInAction } from "mobx";

// src/store/DamStore/DamUtils.js
var DamUtils = class {
  constructor() {
    __publicField(this, "transformPersonaResponseIntoModel", (response) => {
      return response;
    });
    __publicField(this, "transformResponseIntoSearchItems", (response) => {
      return response;
    });
    __publicField(this, "transformDataToApiMoveToFolder", (data) => {
      return data;
    });
  }
};
var utils = new DamUtils();
var DamUtils_default = utils;

// src/store/DamStore/DamStore.js
import { saveAs } from "file-saver";
var DamStore = class {
  constructor() {
    __publicField(this, "getSubscription", async () => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.getDamSubscription();
        if (responseDataFromLibrary) {
          return responseDataFromLibrary;
        }
      } catch (error) {
        return error;
      }
    });
    __publicField(this, "updateSubscription", async (data) => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.updateDamSubscription(data);
        if (responseDataFromLibrary) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return error;
      }
    });
    __publicField(this, "goToFolder", async (collectionId, dataFilter, isFetchCollection, isFetchAssets, callbackOnSuccess, callbackOnError) => {
      try {
        let collections = [];
        let assets = [];
        let totalAsset = 0;
        const damService = new AesirxDamApiService();
        if (isFetchCollection) {
          const responsedDataCollectionFromLibary = await damService.getCollections(
            collectionId,
            dataFilter
          );
          if (responsedDataCollectionFromLibary?.list) {
            const collectionsData = DamUtils_default.transformPersonaResponseIntoModel(
              responsedDataCollectionFromLibary.list
            );
            collections = [...collectionsData];
          }
        }
        if (isFetchAssets) {
          const responsedDataAssetsFromLibary = await damService.getAssets(collectionId, dataFilter);
          if (responsedDataAssetsFromLibary?.list) {
            const assetsData = DamUtils_default.transformPersonaResponseIntoModel(
              responsedDataAssetsFromLibary.list
            );
            assets = [...assetsData];
          }
          if (responsedDataAssetsFromLibary?.pagination) {
            totalAsset = responsedDataAssetsFromLibary?.pagination?.totalItems;
          }
        }
        runInAction(() => {
          callbackOnSuccess({
            collections,
            assets,
            totalAsset
          });
        });
      } catch (error) {
        runInAction(() => {
          if (error.response?.data.message) {
            callbackOnError({
              message: error.response?.data?.message
            });
          } else {
            callbackOnError({
              message: error.response?.data?._messages[0]?.message ?? "Something went wrong from Server response"
            });
          }
        });
      }
    });
    __publicField(this, "getAllCollections", async () => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.getCollections();
        if (responseDataFromLibrary?.list) {
          const collectionDataModel = responseDataFromLibrary?.list;
          if (collectionDataModel) {
            return collectionDataModel;
          }
        } else {
          return [];
        }
      } catch (error) {
        return error;
      }
    });
    __publicField(this, "createCollections", async (data, callbackOnSuccess, callbackOnError) => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.createCollections(data);
        if (responseDataFromLibrary) {
          runInAction(() => {
            callbackOnSuccess({
              data,
              item: responseDataFromLibrary,
              type: "create"
            });
          });
        } else {
          runInAction(() => {
            callbackOnError({
              message: "Something went wrong from Server response"
            });
          });
        }
      } catch (error) {
        runInAction(() => {
          if (error.response?.data?.message) {
            callbackOnError({
              message: error.response?.data.message
            });
          } else {
            callbackOnError({
              message: error.response?.data?._messages[0]?.message ?? "Something went wrong from Server response"
            });
          }
        });
      }
    });
    __publicField(this, "downloadCollections", async (id) => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.downloadCollections(id);
        if (responseDataFromLibrary) {
          saveAs(responseDataFromLibrary, "aesirx-dam-assets.zip");
          return responseDataFromLibrary;
        } else {
          return false;
        }
      } catch (error) {
        return error;
      }
    });
    __publicField(this, "updateCollections", async (data, callbackOnSuccess, callbackOnError) => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.updateCollections(data);
        if (responseDataFromLibrary) {
          runInAction(() => {
            callbackOnSuccess({
              item: data,
              type: "update"
            });
          });
        } else {
          runInAction(() => {
            callbackOnError({
              message: "Something went wrong from Server response"
            });
          });
        }
      } catch (error) {
        runInAction(() => {
          if (error.response?.data.message) {
            callbackOnError({
              message: error.response?.data?.message
            });
          } else {
            callbackOnError({
              message: error.response?.data?._messages[0]?.message ?? "Something went wrong from Server response"
            });
          }
        });
      }
    });
    __publicField(this, "deleteCollections", async (data, callbackOnSuccess, callbackOnError) => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.deleteCollections(data);
        if (responseDataFromLibrary) {
          runInAction(() => {
            callbackOnSuccess({
              item: data,
              type: "delete"
            });
          });
        } else {
          runInAction(() => {
            callbackOnError({
              message: "Something went wrong from Server response"
            });
          });
        }
      } catch (error) {
        runInAction(() => {
          if (error.response?.data.message) {
            callbackOnError({
              message: error.response?.data?.message
            });
          } else {
            callbackOnError({
              message: error.response?.data?._messages[0]?.message ?? "Something went wrong from Server response"
            });
          }
        });
      }
    });
    __publicField(this, "createAssets", async (data, callbackOnSuccess, callbackOnError) => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.createAssets(data);
        if (responseDataFromLibrary.length) {
          runInAction(() => {
            callbackOnSuccess({
              item: responseDataFromLibrary,
              type: "create"
            });
          });
        } else {
          runInAction(() => {
            callbackOnError({
              message: "Something went wrong from Server response"
            });
          });
        }
      } catch (error) {
        runInAction(() => {
          if (error.response?.data.message) {
            callbackOnError({
              message: error.response?.data?.message
            });
          } else {
            callbackOnError({
              message: error.response?.data?._messages[0]?.message ?? "Something went wrong from Server response"
            });
          }
        });
      }
    });
    __publicField(this, "updateAssets", async (data, callbackOnSuccess, callbackOnError) => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.updateAssets(data);
        if (responseDataFromLibrary) {
          runInAction(() => {
            callbackOnSuccess({
              item: data,
              type: "update"
            });
          });
        } else {
          runInAction(() => {
            callbackOnError({
              message: "Something went wrong from Server response"
            });
          });
        }
      } catch (error) {
        runInAction(() => {
          if (error.response?.data.message) {
            callbackOnError({
              message: error.response?.data?.message
            });
          } else {
            callbackOnError({
              message: error.response?.data?._messages[0]?.message ?? "Something went wrong from Server response"
            });
          }
        });
      }
    });
    __publicField(this, "moveToFolder", async (data, callbackOnSuccess, callbackOnError) => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.moveToFolder(data);
        if (responseDataFromLibrary) {
          runInAction(() => {
            callbackOnSuccess({
              collections: data[DAM_COLLECTION_FIELD_KEY.COLLECTIONIDS],
              assets: data[DAM_COLLECTION_FIELD_KEY.ASSETSIDS],
              parentCollection: data[DAM_COLLECTION_FIELD_KEY.PARENT_ID]
            });
          });
        } else {
          runInAction(() => {
            callbackOnError({
              message: "Something went wrong from Server response"
            });
          });
        }
      } catch (error) {
        runInAction(() => {
          if (error.response?.data.message) {
            callbackOnError({
              message: error.response?.data?.message
            });
          } else {
            callbackOnError({
              message: error.response?.data?._messages[0]?.message ?? "Something went wrong from Server response"
            });
          }
        });
      }
    });
    __publicField(this, "deleteAssets", async (data, callbackOnSuccess, callbackOnError) => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.deleteAssets(data);
        if (responseDataFromLibrary) {
          runInAction(() => {
            callbackOnSuccess({
              item: data,
              type: "delete"
            });
          });
        } else {
          runInAction(() => {
            callbackOnError({
              message: "Something went wrong from Server response"
            });
          });
        }
      } catch (error) {
        runInAction(() => {
          if (error.response?.data.message) {
            callbackOnError({
              message: error.response?.data?.message
            });
          } else {
            callbackOnError({
              message: error.response?.data?._messages[0]?.message ?? "Something went wrong from Server response"
            });
          }
        });
      }
    });
    __publicField(this, "search", async (query) => {
      try {
        const damService = new AesirxDamApiService();
        const responseDataFromLibrary = await damService.search({
          "filter[search]": query
        });
        if (responseDataFromLibrary?.assets || responseDataFromLibrary?.collections) {
          const homeDataModels = DamUtils_default.transformResponseIntoSearchItems([
            ...responseDataFromLibrary?.assets ?? [],
            ...responseDataFromLibrary?.collections ?? []
          ]);
          return homeDataModels;
        }
      } catch (error) {
        throw error;
      }
    });
  }
};

export {
  DamStore
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
